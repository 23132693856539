import { Courier, AmbroDefaultPackageType } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import { ShippingService } from "constants/shippingService";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { CollapsibleSection } from "pages/logistics/shared/CollapsibleSection";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  courier: Courier;
}

export const DefaultOptionsSection = (props: Props) => {
  switch (props.courier.provider) {
    case ShippingService.AMBRO: {
      return <Ambro {...props} />;
    }
    case ShippingService.DPD: {
      return <DPD {...props} />;
    }
    case ShippingService.GLS: {
      return <GLS {...props} />;
    }
    case ShippingService.MEBEL_TAXI: {
      return null;
    }
    case ShippingService.SPT: {
      return null;
    }
    case ShippingService.POCZTEX: {
      return null;
    }
    case ShippingService.ZADBANO: {
      return null;
    }
    case ShippingService.BJ_LOGISTICS: {
      return null;
    }
    case ShippingService["NOT CONNECTED"]: {
      return null;
    }

    default:
      const exhaustiveCheck: never = props.courier.provider;
      throw new Error(`Unhandled courier case: ${exhaustiveCheck}`);
  }
};

const GLS = ({ courier }: Props) => {
  const glsDefaultAdditionalServicesMutation = shippingActions.useCourierPatchMutation();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={courier.glsDefaultAdditionalServicesOptions}
          selectedOptions={courier.glsDefaultAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      courier.glsDefaultAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const glsDefaultAdditionalServices = status
                        ? [...courier.glsDefaultAdditionalServices, option.id]
                        : courier.glsDefaultAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      glsDefaultAdditionalServicesMutation.mutate({
                        id: courier.id,
                        toUpdate: {
                          glsDefaultAdditionalServices,
                        },
                      });
                    }}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};

const DPD = ({ courier }: Props) => {
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="Identyfikator klienta">
        <TextField.Async
          size="small"
          placeholder="Wpisz identyfikator klienta"
          mutationHook={shippingActions.useCourierPatchMutation}
          transformQueryData={dpdMasterFid => ({ id: courier.id, toUpdate: { dpdMasterFid } })}
          value={courier.dpdMasterFid}
        />
      </InfoLabel>
    </RightPanelSection>
  );
};

const Ambro = ({ courier }: Props) => {
  const ambroDefaultPackageTypeMutation = shippingActions.useCourierPatchMutation();
  const ambroDefaultServiceMutation = shippingActions.useCourierPatchMutation();
  const ambroDefaultAdditionalServicesMutation = shippingActions.useCourierPatchMutation();
  return (
    <RightPanelSection padding="px-3 pt-0 pb-0">
      <InfoLabel title="domyślny typ paczki">
        <Select
          items={Object.values(AmbroDefaultPackageType).map(option => ({
            value: option,
            text: option,
            type: MenuItemType.TEXT,
          }))}
          onChange={ambroDefaultPackageType => {
            assertIsDefined(ambroDefaultPackageType);
            ambroDefaultPackageTypeMutation.mutate({
              id: courier.id,
              toUpdate: {
                ambroDefaultPackageType: ambroDefaultPackageType as AmbroDefaultPackageType,
              },
            });
          }}
          selected={courier.ambroDefaultPackageType}
        />
      </InfoLabel>
      <InfoLabel title="domyślny typ usługi">
        <Select
          items={courier.ambroServicesOptions.map(option => ({
            value: option.id,
            text: option.name,
            type: MenuItemType.TEXT,
          }))}
          onChange={id => {
            const ambroDefaultService = courier.ambroServicesOptions.find(
              service => service.id === id,
            );
            assertIsDefined(ambroDefaultService);
            ambroDefaultServiceMutation.mutate({
              id: courier.id,
              toUpdate: { ambroDefaultService: ambroDefaultService.id },
            });
          }}
          selected={courier.ambroDefaultService}
        />
      </InfoLabel>
      <InfoLabel title="usługi dodatkowe" className="align-items-start mt-1">
        <CollapsibleSection
          options={courier.ambroAdditionalServicesOptions}
          selectedOptions={courier.ambroDefaultAdditionalServices}
        >
          {options => (
            <div className="d-flex flex-column">
              {options.map(option => (
                <div key={option.id}>
                  <Checkbox
                    label={option.name}
                    checked={Boolean(
                      courier.ambroDefaultAdditionalServices.find(service => service === option.id),
                    )}
                    onChange={status => {
                      const ambroDefaultAdditionalServices = status
                        ? [...courier.ambroDefaultAdditionalServices, option.id]
                        : courier.ambroDefaultAdditionalServices.filter(
                            addedOption => addedOption !== option.id,
                          );

                      ambroDefaultAdditionalServicesMutation.mutate({
                        id: courier.id,
                        toUpdate: {
                          ambroDefaultAdditionalServices,
                        },
                      });
                    }}
                    size="sm"
                  />
                </div>
              ))}
            </div>
          )}
        </CollapsibleSection>
      </InfoLabel>
    </RightPanelSection>
  );
};
